
import { Component, Vue } from 'vue-property-decorator';

import FormInput from '@/components/common/FormInput.vue';
import ErrorDisplay from '@/components/common/ErrorDisplay.vue';

import User from '@/store/modules/User';
import ApiError from '@/services/api/models/ApiError';

import { Validation, validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

@Component({
  components: {
    FormInput,
    ErrorDisplay
  },
  validations: {
    form: {
      newPassword: {
        required
      },
      confirmPassword: {
        required
      }
    }
  },
  mixins: [validationMixin]
})
export default class Verify extends Vue {
  public error: ApiError | null = null;
  public form = {
    newPassword: '',
    confirmPassword: ''
  };
  public attemptSubmit: boolean | null = null;
  public submitted = false;
  public errorMessage = 'Passwords must match';
  public unresolvableError = false;

  public async onSubmit(): Promise<void> {
    this.$v.form.$touch();
    if (this.$v.form.$anyError || !this.validatePassword()) {
      this.attemptSubmit = false;
      return;
    }
    this.error = null;
    try {
      await User.verify({
        userId: this.$route.params.userId,
        expires: this.$route.query.expires as string,
        signature: this.$route.query.signature as string,
        password: this.form.confirmPassword
      });
      this.submitted = true;
    } catch (error) {
      if (
        (error as ApiError).status == 401 ||
        (error as ApiError).status == 404
      ) {
        this.unresolvableError = true;
        this.submitted = true;
      } else {
        this.error = error as ApiError;
      }
    }
  }

  public validatePassword(): boolean | null {
    if (this.form.newPassword.length < 8) {
      this.errorMessage = 'Password must be at least 8 characters long';
      return false;
    } else if (this.form.newPassword != this.form.confirmPassword) {
      this.errorMessage = 'Passwords must match';
      return false;
    }
    return true;
  }

  public validateState(name: string): boolean | null {
    const { $dirty, $invalid } = this.$v.form[name] as Validation;
    return $dirty ? !$invalid : null;
  }
}
